import loadable from '@loadable/component';

const AboutPartieBlock = loadable(() => import("./about-partie-block/about-partie-block"));
const Alert = loadable(() => import("./alert/alert"));
const ApplyBlock = loadable(() => import("./apply-block/apply-block"));
const AvatarList = loadable(() => import("./avatar-list/avatar-list"));
const BirthDate = loadable(() => import("./formik-fields/birthdate/birthdate"));
const Block = loadable(() => import("./block/block"));
const CheckBox = loadable(() => import("./formik-fields/checkboxes"));
const DropDown = loadable(() => import("./formik-fields/drop-down"));
const EventsGrid = loadable(() => import("./events-grid/events-grid"));
const FeaturedLeaders = loadable(() => import("./featured-leaders-block/featured-leaders"));
const GetStartedBlock = loadable(() => import("./get-started-block/get-started-block"));
const GetStartedCtas = loadable(() => import("./get-started-ctas/get-started-ctas"));
const GetStartedStackedBlock = loadable(() => import("./get-started-stacked-block/get-started-stacked-block"));
const GetTheAppBlock = loadable(() => import("./get-the-app-block/get-the-app-block"));
const FeaturesBlock = loadable(() => import("./features-block/features-block"));
const TrackedLink = loadable(() => import("./trackers/link-tracker"));
const MainNav = loadable(() => import("./page-header/main-nav/main-nav"));
const MeetLeaders = loadable(() => import("./meet-leaders-block/meet-leaders-block"));
const MobileMenu = loadable(() => import("./page-header/mobile-menu/mobile-menu"));
const PageFooter = loadable(() => import("./page-footer/page-footer"))
const PageHeader = loadable(() => import("./page-header/page-header"));
const PartnersBlock = loadable(() => import("./partners-block/partners-block"));
const PersonalityBlock = loadable(() => import("./personality-block/personality-block"));
const PromoBlock = loadable(() => import("./promo-block/promo-block"));
const ArrowButton = loadable(() => import("./scroll/scroll"));
const RadioButton = loadable(() => import("./formik-fields/radio-button"));
const SecondaryNav = loadable(() => import("./page-header/secondary-nav/secondary-nav"));
const TextArea = loadable(() => import("./formik-fields/text-area"));
const TextField = loadable(() => import("./formik-fields/text-field"));
const TournamentAvatarList = loadable(() => import("./avatar-list/tournament-avatar-list"));
const TournamentBracket = loadable(() => import("./tournament-bracket/tournament-bracket"));
const TournamentEvents = loadable(() => import("./tournament-events/tournament-events"));
const TournamentHeat = loadable(() => import("./tournament-heat/tournament-heat"));
const TournamentLeaderboard = loadable(() => import("./tournament-leaderboard/tournament-leaderboard"));
const TwitchStreams = loadable(() => import('./twitch-streams/twitch-streams'));

export {
  AboutPartieBlock,
  Alert,
  ApplyBlock,
  AvatarList,
  BirthDate,
  Block,
  CheckBox,
  DropDown,
  EventsGrid,
  FeaturedLeaders,
  GetStartedBlock,
  GetStartedCtas,
  GetStartedStackedBlock,
  FeaturesBlock,
  TrackedLink,
  MainNav,
  MeetLeaders,
  GetTheAppBlock,
  MobileMenu,
  PageFooter,
  PageHeader,
  PartnersBlock,
  PersonalityBlock,
  PromoBlock,
  ArrowButton,
  RadioButton,
  SecondaryNav,
  TextArea,
  TextField,
  TournamentAvatarList,
  TournamentBracket,
  TournamentEvents,
  TournamentHeat,
  TournamentLeaderboard,
  TwitchStreams
};
